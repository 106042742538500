<template>
  <div class="mt-2 wi100 hi100" v-loading="loading">
    <div class="quality-container">
      <el-page-header @back="goBack">
        <span slot="content" class="fwb gb-default-color">综合质量诊断</span>
      </el-page-header>
      <div class="mt-4 ml-4 wi100 header-content">
        <div class="wi60">
          <div class="uf">
            <div class="mt-1" style="margin: auto 0">
              <el-avatar shape="circle" size="large" :src="enterpriseInfo.imageUrl" type="round"></el-avatar>
            </div>
            <div class="ml-1">
              <div class="pl-2 mr-2 f20"><span>{{ enterpriseInfo.name }}</span> <el-tag size="mini">{{enterpriseInfo.status}}</el-tag></div>
              <div class="f14 pl-2 mt-2" style="color: #909399">
                <span>{{enterpriseInfo.englishName}}</span>
                <el-divider direction="vertical"></el-divider>
                <span>{{enterpriseInfo.taxpayerType}}</span>
                <el-divider direction="vertical"></el-divider>
                <span>官网：{{enterpriseInfo.websiteUrl}}</span>
                <el-divider direction="vertical"></el-divider>
                <span>成立日期：{{enterpriseInfo.startDate | dateFilter}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="header-extra tac wi40">
          <div>
            <div class="second-color">公司规模</div>
            <div class="fwb f18 mt-2" style="min-width: 180px">{{enterpriseInfo.personScope}}</div>
          </div>
          <div class="custom-divider"></div>
          <div>
            <div class="second-color">注册资本</div>
            <div class="fwb f18 mt-2" style="min-width: 180px">{{enterpriseInfo.registCapi}}</div>
          </div>
          <div>
            <div class="second-color">实缴资本</div>
            <div class="fwb f18 mt-2" style="min-width: 180px">{{enterpriseInfo.recCap}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-center m-4" style="margin-right: 1.5rem">
      <div class="wi80">
          <el-card class="mt-3" shadow="never">
            <el-descriptions title="经营及荣誉分值" :labelStyle="labelStyle" :contentStyle="contentStyle" :columns="3" size="small" border>
              <el-descriptions-item>
                <span slot="label">资金规模分值</span>
                {{creditEntity.registcapiScore}}
              </el-descriptions-item>
              <el-descriptions-item>
                <span slot="label">经营时长分值</span>
                {{creditEntity.operationTimeScore}}
              </el-descriptions-item>
              <el-descriptions-item>
                <span slot="label">经营稳定性分值</span>
                {{creditEntity.eciChangeScore}}
              </el-descriptions-item>
              <el-descriptions-item>
                <span slot="label">资质许可分值</span>
                {{creditEntity.qLicensingScore}}
              </el-descriptions-item>
              <el-descriptions-item>
                <span slot="label">荣誉奖励分值</span>
                {{creditEntity.honourScore}}
              </el-descriptions-item>
              <el-descriptions-item>
                <span slot="label">红名单信息分值</span>
                {{creditEntity.redlistScore}}
              </el-descriptions-item>
            </el-descriptions>
            <el-descriptions title="知识产权分值" :labelStyle="labelStyle" :contentStyle="contentStyle" class="mt-4" :columns="3" size="small" border>
              <el-descriptions-item>
                <span slot="label">知识产权分值</span>
                {{creditEntity.iprScore}}
              </el-descriptions-item>
              <el-descriptions-item>
                <span slot="label">商标分值</span>
                {{creditEntity.brandNumScore}}
              </el-descriptions-item>
              <el-descriptions-item>
                <span slot="label">专利、著作权分值</span>
                {{creditEntity.patentCopyrightNumScore}}
              </el-descriptions-item>
            </el-descriptions>
            <el-descriptions title="第三方评价分值" :labelStyle="labelStyle" :contentStyle="contentStyle" class="mt-4" :columns="3" size="small" border>
              <el-descriptions-item>
                <span slot="label">第三方评价分值</span>
                {{creditEntity.tirdEvaluationScore}}
              </el-descriptions-item>
              <el-descriptions-item>
                <span slot="label">行业评价分值</span>
                {{creditEntity.industryEvaluationScore}}
              </el-descriptions-item>
              <el-descriptions-item>
                <span slot="label">信用评级分值</span>
                {{creditEntity.creditRatingScore}}
              </el-descriptions-item>
            </el-descriptions>
            <el-descriptions title="市场活跃度分值" :labelStyle="labelStyle" :contentStyle="contentStyle" class="mt-4" :columns="3" size="small" border>
              <el-descriptions-item>
                <span slot="label">市场活跃分值</span>
                {{creditEntity.marketActivityScore}}
              </el-descriptions-item>
              <el-descriptions-item>
                <span slot="label">中标数量分值</span>
                {{creditEntity.winBiddingNumScore}}
              </el-descriptions-item>
              <el-descriptions-item>
                <span slot="label">招标数量分值</span>
                {{creditEntity.bidInvitingNumScore}}
              </el-descriptions-item>
            </el-descriptions>
            <el-descriptions title="业务拓展" :labelStyle="labelStyle" :contentStyle="contentStyle" class="mt-4" :columns="3" border>
              <el-descriptions-item>
                <span slot="label">业务拓展分值</span>
                {{creditEntity.businessDevelopScore}}
              </el-descriptions-item>
              <el-descriptions-item>
                <span slot="label">分支机构数量分值</span>
                {{creditEntity.branchNumScore}}
              </el-descriptions-item>
              <el-descriptions-item>
                <span slot="label">对外投资企业数量分值</span>
                {{creditEntity.investmentNumScore}}
              </el-descriptions-item>
            </el-descriptions>
            <el-descriptions title="公益活动分值" :labelStyle="labelStyle" :contentStyle="contentStyle" class="mt-4" :columns="3" size="small" border>
              <el-descriptions-item>
                <span slot="label">公益活动分值</span>
                {{creditEntity.caScore}}
              </el-descriptions-item>
              <el-descriptions-item>
                <span slot="label">慈善捐助分值</span>
                {{creditEntity.charitableGivingScore}}
              </el-descriptions-item>
              <el-descriptions-item>
                <span slot="label">志愿活动分值</span>
                {{creditEntity.volunteerNumScore}}
              </el-descriptions-item>
            </el-descriptions>
            <el-descriptions title="司法诚信" :labelStyle="labelStyle" :contentStyle="contentStyle" :columns="3" size="small" border class="mt-4">
                <el-descriptions-item>
                  <span slot="label" :labelStyle="labelStyle">
                    失信被执行人得分
                  </span>
                  {{creditEntity.shixinScore}}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    被执行人得分
                  </template>
                  {{creditEntity.zhixingScore}}
                </el-descriptions-item>
              </el-descriptions>
            <el-descriptions title="社会诚信" :labelStyle="labelStyle" :contentStyle="contentStyle" :columns="3" size="small" border class="mt-4">
                <el-descriptions-item>
                  <span slot="label">被行业主管部门列入黑名单</span>
                  {{creditEntity.blacklistScore}}
                </el-descriptions-item>
              <el-descriptions-item>
                <span slot="label">特定严重行政处罚（近 3 年）</span>
                {{creditEntity.severeAdminPenaltyScore}}
              </el-descriptions-item>
              <el-descriptions-item>
                <span slot="label">严重危害人民群众身体健康和生命安全行为（近1年）</span>
                {{creditEntity.safetyHazardScore}}
              </el-descriptions-item>
              <el-descriptions-item>
                <span slot="label">严重破坏市场公平竞争秩序和社会正常秩序行为（近1年）</span>
                {{creditEntity.breakOrderScore}}
              </el-descriptions-item>
              <el-descriptions-item>
                <span slot="label">一般行政处罚</span>
                {{creditEntity.generalAdminPenaltyScore}}
              </el-descriptions-item>
              <el-descriptions-item>
                <span slot="label">行政强制执行</span>
                {{creditEntity.adminEnforceScore}}
              </el-descriptions-item>
              <el-descriptions-item>
                <span slot="label">监督检查不合格等其他失信情况</span>
                {{creditEntity.otherShixinScore}}
              </el-descriptions-item>
              <el-descriptions-item>
                <span slot="label">异常经营</span>
                {{creditEntity.abnormalOperationScore}}
              </el-descriptions-item>
              <el-descriptions-item>
                <span slot="label">末级评价</span>
                {{creditEntity.endLevelEvaluationScore}}
              </el-descriptions-item>
              <el-descriptions-item>
                <span slot="label">合同履约失信（近1年）</span>
                {{creditEntity.contractSxScore}}
              </el-descriptions-item>
              <el-descriptions-item>
                <span slot="label">商事服务失信（近1年）</span>
                {{creditEntity.businessSxScore}}
              </el-descriptions-item>
              <el-descriptions-item>
                <span slot="label">负面舆情</span>
                {{creditEntity.negativeOpinionScore}}
              </el-descriptions-item>
              <el-descriptions-item>
                <span slot="label">高层管理人员失信情况（近1年）</span>
                {{creditEntity.seniorStaffSxScore}}
              </el-descriptions-item>
              <el-descriptions-item>
                <span slot="label">高层管理人员失信情况（近1年）</span>
                {{creditEntity.seniorStaffSxScore}}
              </el-descriptions-item>
              <el-descriptions-item>
                <span slot="label">企业法定代表人刑事判决</span>
                {{creditEntity.operCriminalJudgmentScore}}
              </el-descriptions-item>
              <el-descriptions-item>
                <span slot="label">非独立法定代表人分公司及分支机构信用</span>
                {{creditEntity.branchCreditScore}}
              </el-descriptions-item>
            </el-descriptions>
          </el-card>
      </div>
    </div>
    <recommend-product ref="product"></recommend-product>
  </div>
</template>
<script>
import {head} from 'lodash'
import moment from 'moment'
import RecommendProduct from '../../components/recommend-product'
export default {
  name: 'general-quality-result',
  filters: {
    dateFilter (date) {
      return moment(date).format('yyyy-MM-DD')
    }
  },
  components: {
    RecommendProduct
  },
  data() {
    return {
      loading: true,
      creditEntity: {},
      enterpriseInfo: {},
      tagList: [],
      qualityInfo: {},
      loadingCount: 2,
      labelStyle: {
        width: '350px',
        textAlign: 'center'
      },
      contentStyle: {
        width: '200px',
        textAlign: 'center'
      }
    }
  },
  computed: {
    enterpriseList: {
      get() {
        return this.$store.state.user.info
      },
      // set (val) { this.$store.commit('user/updateInfo', val) }
    },
    enterpriseId() {
      // lodash的_.head方法取第一个企业信息
      let defaultEnterpriseInfo = head(this.enterpriseList)
      return defaultEnterpriseInfo ? defaultEnterpriseInfo.id : ''
    }
  },
  watch: {
    loadingCount (val) {
      if (val === 0) {
        this.loading = false
      } else {
        this.loading = true
      }
    }
  },
  created() {
    this.initQuality()
    this.initBasicInfo()
  },
  mounted() {
    this.initRecommendProduct()
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    // 基本信息
    initBasicInfo() {
      this.loading = true
      this.$http({
        url: this.$http.adornUrl(this.$api.QCC.ENT_INFO.INFO),
        method: 'GET',
        params: this.$http.adornParams({
          enterpriseId: this.enterpriseId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.enterpriseInfo = data['result'] || {}
          this.tagList = this.enterpriseInfo['qccEntTagList'] || []
        }
        if (this.loadingCount) {
          this.loadingCount -=1
        }
      })
    },
    initRecommendProduct() {
      this.$nextTick(() => {
        this.$refs.product.init(this.enterpriseId)
      })
    },
    initQuality() {
      this.$http({
        url: this.$http.adornUrl(this.$api.QCC.CREDIT.INFO),
        method: 'GET',
        params: this.$http.adornParams({
          enterpriseId: this.enterpriseId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.qualityInfo = data['result'] || {}
          let creditRateEntity = this.qualityInfo['qccCreditRatingEntity']
          let qccCreditAdjustEntity = this.qualityInfo['qccCreditAdjustEntity']
          let qccCreditAdjustBdEntity = qccCreditAdjustEntity['qccCreditAdjustBdEntity'] || []
          let qccCreditAdjustCaEntity = qccCreditAdjustEntity['qccCreditAdjustCaEntity'] || []
          let qccCreditAdjustIprEntity = qccCreditAdjustEntity['qccCreditAdjustIprEntity'] || []
          let qccCreditAdjustMaEntity = qccCreditAdjustEntity['qccCreditAdjustMaEntity'] || []
          let qccCreditAdjustPrEntity = qccCreditAdjustEntity['qccCreditAdjustPrEntity'] || []
          let qccCreditAdjustTeEntity = qccCreditAdjustEntity['qccCreditAdjustTeEntity'] || []
          this.creditEntity = {
            creditLevel: this.qualityInfo.creditLevel,
            creditScore: this.qualityInfo.creditScore, ...creditRateEntity, ...qccCreditAdjustEntity, ...qccCreditAdjustBdEntity,
            ...qccCreditAdjustCaEntity, ...qccCreditAdjustIprEntity, ...qccCreditAdjustMaEntity,
            ...qccCreditAdjustPrEntity, ...qccCreditAdjustTeEntity
          }
        }
        if (this.loadingCount) {
          this.loadingCount -=1
        }
      })
    },
  }
}
</script>

<style scoped>
.quality-container {
  width: 100%;
  background-color: #FFFFFF;
  height: 50%;
  padding: 2rem 2% .4rem 2%;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-bottom: 1.2rem;
}
.content-center {
  background-color: #FFFFFF;
  margin-top: .5rem;
  /*width: 100%;*/
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}
.content-item {
  width: 100%;
  height: 100%;
  /*background-color: #409EFF;*/
}
.header-extra {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  min-width: 200px;
}
.custom-divider {
  height: 100%;
  width: 10px;
  background-color: #f1f2f3;
}
.item-label {
  width: 250px;
  background: red;
}
</style>
